import { Link } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";

export interface CardProps {
  date?: string;
  title: string;
  description?: string;
  slug: any;
  btnText?: string;
}

export default function Card({
  date,
  title,
  description,
  slug,
  btnText,
}: CardProps) {
  return (
    <div className="flex flex-col justify-between gap-4 p-6 h-full w-full rounded-md shadow-md">
      <div>
        {date && <span className="text-sm text-neutral-600">{date}</span>}
        <h3 className="">{title}</h3>
        {description && <p className="mt-4 ">{description}</p>}
      </div>
      <div>
        <Link
          to={slug}
          className="group text-accent mt-auto hover:no-underline focus:no-underline">
          <span>
            <Trans>{btnText}</Trans>
          </span>{" "}
          <span className="inline-block transform transition-transform group-hover:translate-x-2 ">
            &#10230;
          </span>
        </Link>
      </div>
    </div>
  );
}

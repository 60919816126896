import { graphql, Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import Card from "../components/Card";

import SEO from "../components/SEO";
import { t } from "i18next";
import CardsGrid from "../components/CardsGrid";

// markup
const AllBlogsPage = ({ data }: { data: any }) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  // all posts
  const posts = data.allGraphCmsPost.nodes;

  console.log(posts);
  return (
    <>
      <SEO title={t("Blog")} />
      <Layout>
        <section className="">
          <h2 className="text-center mb-10">
            <Trans>Blog</Trans>
          </h2>

          {/* all blog posts */}
          <CardsGrid>
            {posts.map((post: any) => {
              const { date, excerpt: description, slug, title } = post;

              return (
                <li key={slug} className=" w-full">
                  <Card
                    date={date}
                    title={title}
                    description={description}
                    slug={slug}
                    btnText={t("Read")}
                  />
                </li>
              );
            })}
          </CardsGrid>
          <p className="text-center  mt-14 text-gray-400">
            <Trans>No more posts</Trans>
          </p>
        </section>
      </Layout>
    </>
  );
};

export default AllBlogsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allGraphCmsPost(
      filter: { language: { eq: $language } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        excerpt
        date
        slug
        title
      }
    }
  }
`;
